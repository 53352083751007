@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto&display=swap");
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  text-decoration: none;
  list-style: none;
  font-family: "Roboto", sans-serif;
}

#root {
  background: linear-gradient(to right, #0d0d0d, #161410, #111111);
}

main {
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-snap-points-y: repeat(100vh);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
main::-webkit-scrollbar {
  display: none;
}
main .landing {
  scroll-snap-align: start;
}

h1 {
  font-size: 3.2rem;
  color: #d0d0d0;
  font-weight: 500;
}

h2 {
  font-size: 6.5rem;
  color: #c1ae7a;
  font-weight: 500;
  font-weight: 1.2em;
}

h3 {
  font-size: 5.5rem;
  color: #c1ae7a;
  font-weight: 500;
  font-weight: 1.2em;
  font-style: italic;
}

h4 {
  font-size: 6.5rem;
  color: #d0d0d0;
  font-weight: 1.2em;
}

h5 {
  color: #d0d0d0;
  font-weight: 500;
  margin: 0;
  font-size: 3rem;
  line-height: 5rem;
}

p {
  color: #d0d0d0;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.8rem;
}

button {
  font-size: 2rem;
  color: #d0d0d0;
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid white;
  background-color: #473e21;
  color: white;
  cursor: pointer;
}
button:hover {
  background: #b19957;
}

.landing-title {
  font-family: "Libre Baskerville", "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 7.5rem;
  color: #d0d0d0;
  margin: 1rem 0rem;
}

.baskerville {
  font-family: "Libre Baskerville", "Roboto", sans-serif;
}

.gold-text {
  color: #c1ae7a;
}

.white-text {
  color: #d0d0d0;
}

.italic {
  font-style: italic;
}

.error-landing .error-information {
  width: 66%;
  text-align: center;
}
.error-landing .error-information h1 {
  font-size: 15rem;
  margin: 1rem 0;
}
.error-landing .error-information h2 {
  font-size: 3.2rem;
  margin: 1rem 0;
  line-height: 4.8rem;
}

.soon-landing .left-bottle {
  content: "";
  position: absolute;
  width: 134px;
  height: 300px;
  z-index: 200;
  background-image: url("/src/assets/images/components/alcool.svg");
  background-size: auto;
  transform: rotate(45deg);
  left: 92px;
  scale: 2.4;
  bottom: 231px;
  filter: brightness(0.8);
}
.soon-landing .bottom-bottle {
  content: "";
  position: absolute;
  width: 164px;
  height: 350px;
  z-index: 200;
  background-image: url("/src/assets/images/components/angled alcool.svg");
  bottom: -168px;
  right: 74px;
  scale: 2.89;
}
.soon-landing .scroll {
  content: "";
  position: absolute;
  width: 140px;
  height: 136px;
  z-index: 200;
  background-image: url("/src/assets/images/components/landing-scroll-icon.svg");
  bottom: 19%;
  left: 45.5%;
  scale: 2.3;
  rotate: 30deg;
}
.soon-landing .soon-title-page {
  width: 50%;
  text-align: center;
  padding-bottom: 6rem;
}
.soon-landing .soon-title-page h1 {
  font-size: 8.9rem;
  margin: 1rem 0;
  color: #d0d0d0;
}
.soon-landing .soon-title-page h2 {
  font-size: 2.9rem;
  margin: 1rem 0;
  line-height: 4.8rem;
}

.soon {
  display: flex;
  width: 82%;
  margin: auto;
  padding: 10rem 0;
}
.soon .left {
  flex: 2;
  padding-right: 8%;
}
.soon .left h3 {
  font-size: 6.5rem;
  margin: 1rem 0;
  padding-bottom: 2rem;
  font-style: italic;
  color: #c1ae7a;
  font-weight: 100;
}
.soon .left p {
  font-size: 2.8rem;
  margin: 1rem 0;
  line-height: 3.8rem;
}
.soon .left p:first-of-type {
  font-size: 3rem;
  line-height: 5rem;
}
.soon .right {
  flex: 1;
}
.soon .right .card {
  position: relative;
  height: 100%;
  min-height: 300px;
  margin-left: auto;
  background: #0f0f0f;
  border-radius: 1rem;
  border: 3px solid #29261d;
  padding: 2rem;
  color: #d0d0d0;
}
.soon .right .card h4 {
  font-size: 2.8rem;
  margin: 1rem 0.25rem;
  font-style: italic;
  letter-spacing: 0.25rem;
}
.soon .right .card p {
  font-size: 2.5rem;
  line-height: 3.5rem;
}
.soon .right .card .bottle {
  content: "";
  position: absolute;
  width: 125px;
  height: 238px;
  z-index: 200;
  background-image: url("/src/assets/images/components/alcool.svg");
  background-size: auto;
  transform: rotate(8deg);
  left: 62%;
  scale: 2;
  bottom: 120px;
}

.home-landing .upside-down-bottle {
  content: "";
  position: absolute;
  width: 218px;
  height: 300px;
  z-index: 200;
  background-image: url("/src/assets/images/components/bouteille.svg");
  background-size: auto;
  transform: rotate(180deg);
  top: -5px;
  left: 389px;
  scale: 2.1;
  filter: brightness(0.4);
}
.home-landing .right-bottle {
  content: "";
  position: absolute;
  width: 231px;
  height: 350px;
  z-index: 200;
  background-image: url("/src/assets/images/components/bouteille.svg");
  bottom: 0;
  right: 298px;
  scale: 2.6;
}
.home-landing .scroll {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: 200;
  background-image: url("/src/assets/images/components/landing-scroll-icon.svg");
  bottom: 123px;
  left: 116px;
  scale: 2.3;
  rotate: -5deg;
}
.home-landing .landing-text {
  /* background-color: blue; */
  text-align: center;
  width: 70%;
  position: relative;
  z-index: 300;
}
.home-landing .landing-text h5 {
  width: 50%;
  margin: auto;
}

/* Large devices (Monitors, 1440px and down) 
@media only screen and (min-width: $monitorScreenMin) {    
}

/* Medium devices (Laptop, 1280px and down
@media only screen and (max-width: $laptopScreenMax) {
}

/* Small devices (tablets, 992px and down
@media only screen and (max-width: $tabletScreenMax) {
}
/* Extra small devices (phones, 768px and down)
@media only screen and (max-width: $phoneScreenMax) {
}
/* Tiny devices (phones, 389px and down)
@media only screen and (max-width: $sphoneMax) {
}*/
section.intro-cards .landing .grid {
  width: 80%;
  height: 80%;
  margin: auto auto 5rem auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15%;
}
section.intro-cards .landing .grid figure.card {
  width: 100%;
  padding: 4rem;
  background: #0f0f0f;
  /* white */
  border: 1.26022px solid #ffffff;
  border-radius: 20.1635px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}
section.intro-cards .landing .grid figure.card img {
  width: 90%;
  margin: auto;
}
section.intro-cards .landing .grid figure.card figcaption h3 {
  margin: 0 1rem;
}

.intro-artist {
  display: grid;
  width: 85%;
  padding: 10rem 0;
  grid-template-columns: 1fr 2fr;
}
.intro-artist .left {
  margin-right: 5%;
}
.intro-artist .left figure.card {
  width: 100%;
  padding: 4rem;
  background: #0f0f0f;
  /* white */
  border: 1.26022px solid #ffffff;
  border-radius: 20.1635px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}
.intro-artist .left figure.card img {
  width: 90%;
  margin: auto;
}
.intro-artist .left figure.card figcaption h3 {
  margin: 0 1rem;
}
.intro-artist .right {
  margin-left: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-spirit {
  display: grid;
  width: 85%;
  padding: 5rem 0 auto 0;
  grid-template-columns: 2fr 3fr;
}
.intro-spirit .left {
  margin-right: 5%;
}
.intro-spirit .left .card {
  width: 100%;
  padding: 4rem 0 4rem 3rem;
  background: #0f0f0f;
  /* white */
  border: 1.26022px solid #ffffff;
  border-radius: 20.1635px;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  margin: auto;
  position: relative;
}
.intro-spirit .left .card h4 {
  width: 70%;
  margin: 0;
  font-size: 5.5rem;
  line-height: 7rem;
}
.intro-spirit .left .card p {
  width: 55%;
}
.intro-spirit .left .card button {
  width: 25%;
  padding: 1rem 1rem;
}
.intro-spirit .left .card img {
  width: 70%;
  margin: auto;
  position: absolute;
  top: 0;
  right: -5rem;
}
.intro-spirit .left .card figcaption h3 {
  margin: 0 1rem;
}
.intro-spirit .right {
  margin-left: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explanation {
  /* background-color: blue; */
  text-align: center;
  width: 70%;
  position: relative;
}
.explanation h2 {
  margin: 4rem 0 0 0;
}
.explanation .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.explanation .grid p.title {
  font-size: 5.5rem;
  line-height: 7rem;
  margin: 0;
  color: #c1ae7a;
}
.explanation .grid img {
  width: 100%;
  margin-bottom: -0.3rem;
}
.explanation .grid figure.card {
  /* white */
  border-width: 1.31668px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 7.0223px;
  padding: 1rem 1rem 0rem 1rem;
}
.explanation .grid figure.middle figcaption {
  /* bg-card-color */
  background: #0f0f0f;
  /* white-grisé */
  border: 1.46318px solid #d2d2d2;
  border-radius: 0px 0px 7.8036px 7.8036px;
}
.explanation .grid figure.middle figcaption p {
  font-size: 2.1rem;
  line-height: 2.5rem;
  margin: 1.5rem 0.5rem;
}
.explanation .grid figure.middle img {
  /* white */
  border-width: 1.46318px 1.46318px 0px 1.46318px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 7.8036px 7.8036px 0px 0px;
}

/* Large devices (Monitors, 1440px and down) 
@media only screen and (min-width: $monitorScreenMin) {    
}

/* Medium devices (Laptop, 1280px and down
@media only screen and (max-width: $laptopScreenMax) {
}

/* Small devices (tablets, 992px and down
@media only screen and (max-width: $tabletScreenMax) {
}
/* Extra small devices (phones, 768px and down)
@media only screen and (max-width: $phoneScreenMax) {
}
/* Tiny devices (phones, 389px and down)
@media only screen and (max-width: $sphoneMax) {
}*/
.teams {
  width: 70%;
  height: 80%;
  margin: auto;
}
.teams h3,
.teams .center,
.teams .members {
  text-align: center;
}
.teams .members {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5%;
  padding-top: 5rem;
}
.teams .members .member img {
  width: 55%;
}
.teams .members .member h5 {
  padding-top: 2rem;
}
.teams .members .member p {
  font-size: 2.2rem;
  line-height: 2.8rem;
}
.teams .members .member p:last-of-type {
  min-height: 5rem;
}
.teams .members .member .social-media p {
  color: #c1ae7a;
  position: relative;
}
.teams .members .member .social-media p .label {
  line-height: 1.5rem;
}
.teams .members .member .social-media p .icon {
  position: absolute;
  top: 8%;
  right: 15%;
}

.socials {
  width: 70%;
  height: 80%;
  margin: auto;
}
.socials h3,
.socials .center,
.socials .links {
  text-align: center;
}
.socials .links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8%;
  padding-top: 5rem;
}
.socials .links .image-container {
  margin-bottom: 2rem;
}
.socials .links img {
  width: 85%;
}
.socials .links p {
  font-size: 2.2rem;
  line-height: 2.8rem;
}

.product-detail {
  width: 85%;
  margin: auto;
  height: 90%;
  display: flex;
  column-gap: 10rem;
}
.product-detail .image-container {
  /* white */
  border: 0.504135px solid #ffffff;
  border-radius: 5.37755px;
  margin: 20rem 0 5rem 0;
  flex: 1;
  display: relative;
}
.product-detail .image-container p {
  font-size: 1.9rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.product-detail .image-container .is-limited {
  display: flex;
  /* bg-card-color */
  background: #0f0f0f;
  border-radius: 4.87331px;
  padding: 0.25rem 2rem;
}
.product-detail .image-container .is-limited > * {
  flex: 1;
}
.product-detail .image-container .is-limited p:last-child {
  text-align: right;
}
.product-detail .image-container img {
  position: absolute;
  bottom: 15%;
  left: 10%;
  width: 7.5%;
}
.product-detail .image-container .img-description {
  text-align: right;
  padding: 0 2rem;
}
.product-detail .image-container .img-description p {
  font-size: 2.7rem;
  margin-top: 3.5rem;
}
.product-detail .image-container .img-description p:first-child {
  font-size: 5.5rem;
  line-height: 6.5rem;
  width: 70%;
  margin-left: auto;
  font-weight: 500;
  font-style: italic;
}
.product-detail .image-container .details {
  position: absolute;
  bottom: 100px;
  display: flex;
  /* bg-card-color */
  background: #0f0f0f;
  border-radius: 4.87331px;
  padding: 0.25rem 2rem;
  width: 26.48%;
}
.product-detail .image-container .details p {
  font-size: 1.8rem;
  text-align: center;
  flex: 2;
  white-space: nowrap;
}
.product-detail .image-container .details p:first-child {
  text-align: left;
  flex: 1;
}
.product-detail .image-container .details p:last-child {
  text-align: right;
  flex: 1;
}
.product-detail .description {
  flex: 2;
  padding-top: 9rem;
}
.product-detail .description h2 {
  margin: 2rem 0 3rem 0;
  font-size: 4.9rem;
}
.product-detail .description .detail {
  max-height: 55%;
  overflow-y: scroll;
  padding: 0 2rem 0 0;
}
.product-detail .description .buttons {
  margin: 2rem 0 1rem 0;
}
.product-detail .description .buttons .adder {
  font-size: 2rem;
  color: #d0d0d0;
  font-weight: 500;
  padding: 1rem 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  /* white */
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  margin: 0 2.5rem 0 0;
}
.product-detail .description .buttons .adder span {
  padding: 1rem 2rem;
}
.product-detail .description .buttons .adder span:not(.count) {
  cursor: pointer;
}
.product-detail .description .buttons .adder span:not(.count):hover {
  background-color: #c1ae7a;
}

.artist-detail {
  width: 85%;
  margin: auto;
  height: 90%;
  display: flex;
  column-gap: 10rem;
}
.artist-detail .image-container {
  /* white */
  border: 0.504135px solid #ffffff;
  border-radius: 5.37755px;
  margin: 20rem 0 5rem 0;
  flex: 1;
  display: relative;
  text-align: center;
}
.artist-detail .image-container p {
  font-size: 1.9rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.artist-detail .image-container .name {
  /* bg-card-color */
  background: #0f0f0f;
  border-radius: 4.87331px;
  padding: 0.25rem 2rem;
}
.artist-detail .image-container .name p {
  font-size: 3.7rem;
  padding: 1rem 0;
}
.artist-detail .image-container img {
  width: 90%;
  margin: auto;
}
.artist-detail .description {
  flex: 2;
  padding-top: 9rem;
}
.artist-detail .description h2 {
  margin: 2rem 0 3rem 0;
  font-size: 4.9rem;
}
.artist-detail .description .detail {
  max-height: 60%;
  overflow-y: auto;
  padding: 0 2rem 0 0;
  margin-bottom: 4rem;
}
.artist-detail .description .mint {
  margin: 0rem 0 2rem 0;
}

.mint {
  text-align: center;
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.mint img {
  width: 30%;
  margin: auto;
}
.mint button {
  width: 10%;
  margin: 3rem auto 0 auto;
}
.mint .buttons {
  margin: 2rem 0 1rem 0;
}
.mint .buttons .adder {
  font-size: 2rem;
  color: #d0d0d0;
  font-weight: 500;
  padding: 1rem 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  /* white */
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  margin: 0;
}
.mint .buttons .adder span {
  padding: 1rem 2rem;
}
.mint .buttons .adder span:not(.count) {
  cursor: pointer;
}
.mint .buttons .adder span:not(.count):hover {
  background-color: #c1ae7a;
}
.mint .buttons .optional {
  display: none;
}

.loader {
  width: 250px;
  aspect-ratio: 1/1;
  background-size: cover;
  border: 5px solid var(--color-primary);
  border-radius: 50%;
  border-top-color: var(--color-white);
  animation: spin 3s ease-in-out infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
    background-image: url("/src/assets/images/logo/logo0.3.svg");
  }
  33% {
    transform: rotate(360deg);
  }
  66% {
    transform: rotate(360deg);
    background-image: url("/src/assets/images/logo/logo1.3.svg");
  }
  88% {
    transform: rotate(360deg);
    background-image: url("/src/assets/images/logo/logo2.3.svg");
  }
  100% {
    transform: rotate(360deg);
    background-image: url("/src/assets/images/logo/logo3.3.svg");
  }
}

.header-nav {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 300;
  font-size: 1.6rem;
}
.header-nav img {
  width: 50px;
  height: 50px;
}
.header-nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 89px;
}
.header-nav ul .nav-left {
  display: flex;
  justify-content: space-evenly;
  /* background-color: red; */
  align-items: center;
  width: 65%;
}
.header-nav ul .nav-left > * {
  margin: 0 30px;
}
.header-nav ul .nav-left li:nth-child(2) {
  position: relative;
}
.header-nav ul .nav-left li:nth-child(2)::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 1px;
  background-color: white;
}
.header-nav ul li a {
  color: white;
  text-decoration: none;
}
.header-nav ul .nav-right {
  width: 300px;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-nav ul .nav-right button {
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-nav ul .nav-right button > * {
  padding: 0 2px;
}
.header-nav ul .nav-right > * {
  margin: 0 20px;
}

#connexion {
  font-size: 1.6rem;
  background-color: #473e21;
}
#connexion:hover {
  background: #b19957;
}

.home-sidebar {
  /* background-color: blue; */
  height: 100%;
  width: 150px;
  position: fixed;
  left: 0;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sidebar {
  width: 2px;
  height: 70%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

#scrollable {
  width: 4px;
  height: 10%;
  background-color: #c1ae7a;
  border-radius: 5px;
  position: relative;
  left: -1px;
  top: 10%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.landing {
  height: 100vh;
  background-image: url("/src/assets/images/components/background-card.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

