.artist-detail {
    width: 85%;
    margin: auto;
    height: 90%;
    display: flex;
    column-gap: 10rem;
    .image-container {
        /* white */
        border: 0.504135px solid #ffffff;
        border-radius: 5.37755px;
        margin: 20rem 0 5rem 0;
        flex: 1;
        display: relative;
        p {
            font-size: 1.9rem;
            font-weight: 500;
            margin: 0;
            padding: 0;
        }
        .name {
            /* bg-card-color */
            background: #0f0f0f;
            border-radius: 4.87331px;
            padding: 0.25rem 2rem;
            p {
                font-size: 3.7rem;
                padding: 1rem 0;
            }
        }
        text-align: center;
        img {
            width: 90%;
            margin: auto;
        }
    }
    .description {
        flex: 2;
        padding-top: 9rem;
        h2 {
            margin: 2rem 0 3rem 0;
            font-size: 4.9rem;
        }
        .detail {
            max-height: 60%;
            overflow-y: auto;
            padding: 0 2rem 0 0;
            margin-bottom: 4rem;
        }
        .mint {
            margin: 0rem 0 2rem 0;
        }
    }
}
