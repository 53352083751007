@import "loading";
@import "header";
@import "sidebar";

.landing {
    height: 100vh;
    // background: linear-gradient(to right, #161410, #111111, #29261d);
    background-image: url("/src/assets/images/components/background-card.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
