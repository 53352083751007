.soon-landing {
    .left-bottle {
        content: "";
        position: absolute;
        width: 134px;
        height: 300px;
        z-index: 200;
        background-image: url("/src/assets/images/components/alcool.svg");
        background-size: auto;
        transform: rotate(45deg);
        left: 92px;
        scale: 2.4;
        bottom: 231px;
        filter: brightness(0.8);
    }
    .bottom-bottle {
        content: "";
        position: absolute;
        width: 164px;
        height: 350px;
        z-index: 200;
        background-image: url("/src/assets/images/components/angled alcool.svg");
        bottom: -168px;
        right: 74px;
        scale: 2.89;
    }
    .scroll {
        content: "";
        position: absolute;
        width: 140px;
        height: 136px;
        z-index: 200;
        background-image: url("/src/assets/images/components/landing-scroll-icon.svg");
        bottom: 19%;
        left: 45.5%;
        scale: 2.3;
        rotate: 30deg;
    }
    .soon-title-page {
        width: 50%;
        text-align: center;
        padding-bottom: 6rem;
        h1 {
            font-size: 8.9rem;
            margin: 1rem 0;
            color: $color-greyed-white;
        }
        h2 {
            font-size: 2.9rem;
            margin: 1rem 0;
            line-height: 4.8rem;
        }
    }
}
.soon {
    display: flex;
    width: 82%;
    margin: auto;
    padding: 10rem 0;
    .left {
        flex: 2;
        padding-right: 8%;
        h3 {
            font-size: 6.5rem;
            margin: 1rem 0;
            padding-bottom: 2rem;
            font-style: italic;
            color: $color-primary;
            font-weight: 100;
        }
        p {
            font-size: 2.8rem;
            margin: 1rem 0;
            line-height: 3.8rem;
            &:first-of-type {
                font-size: 3rem;
                line-height: 5rem;
            }
        }
    }
    .right {
        flex: 1;
        .card {
            position: relative;
            height: 100%;
            min-height: 300px;
            margin-left: auto;
            background: $color-card-bg;
            border-radius: 1rem;
            border: 3px solid $color-card-border;
            padding: 2rem;
            color: $color-greyed-white;
            h4 {
                font-size: 2.8rem;
                margin: 1rem 0.25rem;
                font-style: italic;
                letter-spacing: 0.25rem;
            }
            p {
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
            .bottle {
                content: "";
                position: absolute;
                width: 125px;
                height: 238px;
                z-index: 200;
                background-image: url("/src/assets/images/components/alcool.svg");
                background-size: auto;
                transform: rotate(8deg);
                left: 62%;
                scale: 2;
                bottom: 120px;
            }
        }
    }
}
