.home-sidebar {
    /* background-color: blue; */
    height: 100%;
    width: 150px;
    position: fixed;
    left: 0;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}
#sidebar {
    width: 2px;
    height: 70%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}
#scrollable {
    width: 4px;
    height: 10%;
    background-color: #c1ae7a;
    border-radius: 5px;
    position: relative;
    left: -1px;
    top: 10%;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}
