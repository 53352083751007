.product-detail {
    width: 85%;
    margin: auto;
    height: 90%;
    display: flex;
    column-gap: 10rem;
    .image-container {
        /* white */
        border: 0.504135px solid #ffffff;
        border-radius: 5.37755px;
        margin: 20rem 0 5rem 0;
        flex: 1;
        display: relative;
        p {
            font-size: 1.9rem;
            font-weight: 500;
            margin: 0;
            padding: 0;
        }
        .is-limited {
            display: flex;
            /* bg-card-color */
            background: #0f0f0f;
            border-radius: 4.87331px;
            padding: 0.25rem 2rem;
            & > * {
                flex: 1;
            }
            p:last-child {
                text-align: right;
            }
        }
        img {
            position: absolute;
            bottom: 15%;
            left: 10%;
            width: 7.5%;
        }
        .img-description {
            text-align: right;
            padding: 0 2rem;
            p {
                font-size: 2.7rem;
                margin-top: 3.5rem;
            }
            p:first-child {
                font-size: 5.5rem;
                line-height: 6.5rem;
                width: 70%;
                margin-left: auto;
                font-weight: 500;
                font-style: italic;
            }
        }
        .details {
            position: absolute;
            bottom: 100px;
            display: flex;
            /* bg-card-color */
            background: #0f0f0f;
            border-radius: 4.87331px;
            padding: 0.25rem 2rem;
            width: 26.48%;
            p {
                font-size: 1.8rem;
                text-align: center;
                flex: 2;
                white-space: nowrap;
                &:first-child {
                    text-align: left;
                    flex: 1;
                }
                &:last-child {
                    text-align: right;
                    flex: 1;
                }
            }
        }
    }
    .description {
        flex: 2;
        padding-top: 9rem;
        h2 {
            margin: 2rem 0 3rem 0;
            font-size: 4.9rem;
        }
        .detail {
            max-height: 55%;
            overflow-y: scroll;
            padding: 0 2rem 0 0;
        }
        .buttons {
            margin: 2rem 0 1rem 0;
            .adder {
                font-size: 2rem;
                color: #d0d0d0;
                font-weight: 500;
                padding: 1rem 0;
                transition: all 0.3s ease-in-out;
                display: inline-block;
                /* white */
                border: 0.5px solid #ffffff;
                border-radius: 5px;
                margin: 0 2.5rem 0 0;
                span {
                    padding: 1rem 2rem;
                    &:not(.count) {
                        cursor: pointer;
                        &:hover {
                            background-color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}
