.error-landing {
    .error-information {
        width: 66%;
        text-align: center;
        h1 {
            font-size: 15rem;
            margin: 1rem 0;
        }
        h2 {
            font-size: 3.2rem;
            margin: 1rem 0;
            line-height: 4.8rem;
        }
    }
}
