.intro-spirit {
    display: grid;
    width: 85%;
    padding: 5rem 0 auto 0;
    grid-template-columns: 2fr 3fr;
    .left {
        margin-right: 5%;
        .card {
            width: 100%;
            padding: 4rem 0 4rem 3rem;
            background: #0f0f0f;
            /* white */
            border: 1.26022px solid #ffffff;
            border-radius: 20.1635px;
            display: flex;
            flex-direction: column;
            min-height: 75vh;
            margin: auto;
            position: relative;
            h4 {
                width: 70%;
                margin: 0;
                font-size: 5.5rem;
                line-height: 7rem;
            }
            p {
                width: 55%;
            }
            button {
                width: 25%;
                padding: 1rem 1rem;
            }
            img {
                width: 70%;
                margin: auto;
                position: absolute;
                top: 0;
                right: -5rem;
            }
            figcaption {
                h3 {
                    margin: 0 1rem;
                }
            }
        }
    }
    .right {
        margin-left: 5%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
