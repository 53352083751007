.intro-artist {
    display: grid;
    width: 85%;
    padding: 10rem 0;
    grid-template-columns: 1fr 2fr;
    .left {
        margin-right: 5%;
        figure.card {
            width: 100%;
            padding: 4rem;
            background: #0f0f0f;
            /* white */
            border: 1.26022px solid #ffffff;
            border-radius: 20.1635px;
            text-align: center;
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            img {
                width: 90%;
                margin: auto;
            }
            figcaption {
                h3 {
                    margin: 0 1rem;
                }
            }
        }
    }
    .right {
        margin-left: 5%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
