.teams {
    width: 70%;
    height: 80%;
    margin: auto;
    h3,
    .center,
    .members {
        text-align: center;
    }
    .members {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5%;
        padding-top: 5rem;
        .member {
            img {
                width: 55%;
            }
            h5 {
                padding-top: 2rem;
            }
            p {
                font-size: 2.2rem;
                line-height: 2.8rem;
                &:last-of-type {
                    min-height: 5rem;
                }
            }
            .social-media {
                p {
                    color: $color-primary;
                    position: relative;
                    .label {
                        line-height: 1.5rem;
                    }
                    .icon {
                        position: absolute;
                        top: 8%;
                        right: 15%;
                    }
                }
            }
        }
    }
}
