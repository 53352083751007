section.intro-cards {
    .landing {
        .grid {
            width: 80%;
            height: 80%;
            margin: auto auto 5rem auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 15%;
            figure.card {
                width: 100%;
                padding: 4rem;
                background: #0f0f0f;
                /* white */
                border: 1.26022px solid #ffffff;
                border-radius: 20.1635px;
                text-align: center;
                display: flex;
                flex-direction: column;
                max-height: 80vh;
                img {
                    width: 90%;
                    margin: auto;
                }
                figcaption {
                    h3 {
                        margin: 0 1rem;
                    }
                }
            }
        }
    }
}
