.socials {
    width: 70%;
    height: 80%;
    margin: auto;
    h3,
    .center,
    .links {
        text-align: center;
    }
    .links {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 8%;
        padding-top: 5rem;
        .image-container {
            margin-bottom: 2rem;
        }
        img {
            width: 85%;
        }
        p {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    }
}
