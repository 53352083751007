.explanation {
    /* background-color: blue; */
    text-align: center;
    width: 70%;
    position: relative;
    h2 {
        margin: 4rem 0 0 0;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        p.title {
            font-size: 5.5rem;
            line-height: 7rem;
            margin: 0;
            color: $color-primary;
        }
        img {
            width: 100%;
            margin-bottom: -0.3rem;
        }
        figure.card {
            /* white */
            border-width: 1.31668px;
            border-style: solid;
            border-color: #ffffff;
            border-radius: 7.0223px;
            padding: 1rem 1rem 0rem 1rem;
        }
        figure.middle {
            figcaption {
                /* bg-card-color */
                background: #0f0f0f;
                /* white-grisé */
                border: 1.46318px solid #d2d2d2;
                border-radius: 0px 0px 7.8036px 7.8036px;
                p {
                    font-size: 2.1rem;
                    line-height: 2.5rem;
                    margin: 1.5rem 0.5rem;
                }
            }
            img {
                /* white */
                border-width: 1.46318px 1.46318px 0px 1.46318px;
                border-style: solid;
                border-color: #ffffff;
                border-radius: 7.8036px 7.8036px 0px 0px;
            }
        }
    }
}
/* Large devices (Monitors, 1440px and down) 
@media only screen and (min-width: $monitorScreenMin) {    
}

/* Medium devices (Laptop, 1280px and down
@media only screen and (max-width: $laptopScreenMax) {
}

/* Small devices (tablets, 992px and down
@media only screen and (max-width: $tabletScreenMax) {
}
/* Extra small devices (phones, 768px and down)
@media only screen and (max-width: $phoneScreenMax) {
}
/* Tiny devices (phones, 389px and down)
@media only screen and (max-width: $sphoneMax) {
}*/
