.mint {
    text-align: center;
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
        width: 30%;
        margin: auto;
    }
    button {
        width: 10%;
        margin: 3rem auto 0 auto;
    }
    .buttons {
        margin: 2rem 0 1rem 0;
        .adder {
            font-size: 2rem;
            color: #d0d0d0;
            font-weight: 500;
            padding: 1rem 0;
            transition: all 0.3s ease-in-out;
            display: inline-block;
            /* white */
            border: 0.5px solid #ffffff;
            border-radius: 5px;
            margin: 0;
            span {
                padding: 1rem 2rem;
                &:not(.count) {
                    cursor: pointer;
                    &:hover {
                        background-color: $color-primary;
                    }
                }
            }
        }
        .optional {
            display: none;
        }
    }
}
