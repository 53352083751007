.header-nav {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 300;
    font-size: 1.6rem;
    img {
        width: 50px;
        height: 50px;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: 0 89px;
        .nav-left {
            display: flex;
            justify-content: space-evenly;
            /* background-color: red; */
            align-items: center;
            width: 65%;
        }
        .nav-left > * {
            margin: 0 30px;
        }
        .nav-left li:nth-child(2) {
            position: relative;
        }

        .nav-left li:nth-child(2)::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 1px;
            background-color: white;
        }
        li a {
            color: white;
            text-decoration: none;
        }
        .nav-right {
            width: 300px;
            /* background-color: blue; */
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                background-color: transparent;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                & > * {
                    padding: 0 2px;
                }
            }
            > * {
                margin: 0 20px;
            }
        }
    }
}

#connexion {
    font-size: 1.6rem;
    background-color: #473e21;
    &:hover {
        background: darken($color-primary, 10%);
    }
}
