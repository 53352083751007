h1 {
    font-size: 3.2rem;
    color: $color-greyed-white;
    font-weight: 500;
}
h2 {
    font-size: 6.5rem;
    color: $color-primary;
    font-weight: 500;
    font-weight: 1.2em;
}
h3 {
    font-size: 5.5rem;
    color: $color-primary;
    font-weight: 500;
    font-weight: 1.2em;
    font-style: italic;
}
h4 {
    font-size: 6.5rem;
    color: $color-greyed-white;
    font-weight: 1.2em;
}
h5 {
    color: $color-greyed-white;
    font-weight: 500;
    margin: 0;
    font-size: 3rem;
    line-height: 5rem;
}
p {
    color: $color-greyed-white;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 3.8rem;
}
button {
    font-size: 2rem;
    color: $color-greyed-white;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid white;
    background-color: #473e21;
    color: white;
    cursor: pointer;
    &:hover {
        background: darken($color-primary, 10%);
    }
}

.landing-title {
    font-family: $font-family-baskerville;
    text-transform: uppercase;
    font-size: 7.5rem;
    color: $color-greyed-white;
    margin: 1rem 0rem;
}
.baskerville {
    font-family: $font-family-baskerville;
}
.gold-text {
    color: $color-primary;
}
.white-text {
    color: $color-greyed-white;
}
.italic {
    font-style: italic;
}
