// Font
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto&display=swap");
$font-family: "Roboto", sans-serif;
$font-family-baskerville: "Libre Baskerville", $font-family;
// Colors
$color-primary: #c1ae7a;
$color-secondary: #473e21;
$color-tertiary: #29261d;
$color-card-bg: #0f0f0f;
$color-card-border: #29261d;
$color-greyed-white: #d0d0d0;
$color-greyed-black: #0d0d0d;
$overlay: rgba(16, 14, 14, 0.386);
$gradient-main: linear-gradient(90deg, #171512, $color-tertiary);
// Breakpoints - Screen sizes
$monitorScreenMin: 1440px;
$laptopScreenMax: 1280px;
$tabletScreenMax: 992px;
$phoneScreenMax: 767px;
$sphoneMax: 389px;
