.loader {
    width: 250px;
    aspect-ratio: 1/1;
    background-size: cover;
    border: 5px solid var(--color-primary);
    border-radius: 50%;
    border-top-color: var(--color-white);
    animation: spin 3s ease-in-out infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
            background-image: url("/src/assets/images/logo/logo0.3.svg");
        }
        33% {
            transform: rotate(360deg);
        }
        66% {
            transform: rotate(360deg);
            background-image: url("/src/assets/images/logo/logo1.3.svg");
        }
        88% {
            transform: rotate(360deg);
            background-image: url("/src/assets/images/logo/logo2.3.svg");
        }
        100% {
            transform: rotate(360deg);
            background-image: url("/src/assets/images/logo/logo3.3.svg");
        }
    }
}
