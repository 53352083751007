* {
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    text-decoration: none;
    list-style: none;
    font-family: $font-family;
}
#root {
    background: linear-gradient(
        to right,
        $color-greyed-black,
        #161410,
        #111111
    );
}
main {
    overflow-y: scroll;
    height: 100vh;
    scroll-snap-type: y mandatory;
    scroll-snap-points-y: repeat(100vh);
    //TODO figure out scrollbar issue
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    .landing {
        scroll-snap-align: start;
    }
}
