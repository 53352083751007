.home-landing {
    .upside-down-bottle {
        content: "";
        position: absolute;
        width: 218px;
        height: 300px;
        z-index: 200;
        background-image: url("/src/assets/images/components/bouteille.svg");
        background-size: auto;
        transform: rotate(180deg);
        top: -5px;
        left: 389px;
        scale: 2.1;
        filter: brightness(0.4);
    }
    .right-bottle {
        content: "";
        position: absolute;
        width: 231px;
        height: 350px;
        z-index: 200;
        background-image: url("/src/assets/images/components/bouteille.svg");
        bottom: 0;
        right: 298px;
        scale: 2.6;
    }
    .scroll {
        content: "";
        position: absolute;
        width: 150px;
        height: 150px;
        z-index: 200;
        background-image: url("/src/assets/images/components/landing-scroll-icon.svg");
        bottom: 123px;
        left: 116px;
        scale: 2.3;
        rotate: -5deg;
    }
    .landing-text {
        /* background-color: blue; */
        text-align: center;
        width: 70%;
        position: relative;
        z-index: 300;
        h5 {
            width: 50%;
            margin: auto;
        }
    }
}
/* Large devices (Monitors, 1440px and down) 
@media only screen and (min-width: $monitorScreenMin) {    
}

/* Medium devices (Laptop, 1280px and down
@media only screen and (max-width: $laptopScreenMax) {
}

/* Small devices (tablets, 992px and down
@media only screen and (max-width: $tabletScreenMax) {
}
/* Extra small devices (phones, 768px and down)
@media only screen and (max-width: $phoneScreenMax) {
}
/* Tiny devices (phones, 389px and down)
@media only screen and (max-width: $sphoneMax) {
}*/
